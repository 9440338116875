<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <div class="page__content">
            <div class="sidebar__header">
              <h1 class="sidebar__title">Relatórios</h1>
              <div class="buttons">
                <button @click="downloadItem" type="button" class="button_bg">
                  <i class="fas fa-print"></i>
                  Imprimir relatórios
                </button>
              </div>
            </div>
            <div class="tabs__flex">
              <div class="tabs__filters">
                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">De</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="startDate"
                  />
                </div>

                <div class="tabs__filters--group">
                  <label class="tabs__filters--label">Até</label>
                  <input
                    class="form__input tabs__filters--input"
                    type="date"
                    v-model="endDate"
                  />
                </div>
              </div>
              <div class="tabs__search">
                <form class="search__form">
                  <input
                    class="form__input"
                    type="text"
                    placeholder="Pesquisar..."
                    v-model="search"
                  />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </form>
              </div>
            </div>
            <div class="tabs__flex">
              <div>
                <label>Coletor</label>
                <select @change="getHits" class="col-12 form-control" v-model="filter_collector" :disabled="loading">
                  <option :key="0" :value="''">Selecione o coletor</option>
                  <option v-for="option in list_collector" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div>
                <label>Gerador</label>
                <select @change="getHits" class="col-12 form-control" v-model="filter_generator" :disabled="loading">
                  <option :key="0" :value="''">Selecione o gerador</option>
                  <option v-for="option in list_generator" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div>
                <label>Material</label>
                <select @change="getHits" class="col-12 form-control" v-model="filter_material" :disabled="loading">
                  <option :key="0" :value="''">Selecione o material</option>
                  <option v-for="option in list_material" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div>
                <label>Status</label>
                <select @change="getHits" class="col-12 form-control" v-model="filter_status" :disabled="loading">
                  <option :key="0" :value="''">Selecione o status</option>
                  <option v-for="option in list_status" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div>
                <label>Tipo</label>
                <select @change="getHits" class="col-12 form-control" v-model="filter_type" :disabled="loading">
                  <option :key="0" :value="''">Selecione o tipo</option>
                  <option v-for="option in list_type" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div>
                <label>Cidade</label>
                <select @change="getHits" class="col-12 form-control" v-model="filter_city" :disabled="loading">
                  <option :key="0" :value="''">Selecione a cidade</option>
                  <option v-for="option in list_city" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="pagedCollects.length > 0 && loading === false" class="tab__body current" id="all">
              <table class="table">
                <thead>
                  <tr>
                    <th>Coletor</th>
                    <th>Gerador</th>
                    <th>Material</th>
                    <th>Status</th>
                    <th>Tipo</th>
                    <th>Data</th>
                    <th>Cidade</th>
                    <th>Quant.</th>
                    <th>Un</th>
                    <th>Pago</th>
                    <th>Total</th>
                    <th>Certificado</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="collect in pagedCollects" :key="collect.id">
                    <td>
                      <span> {{ collect.coletor.name }}</span>
                    </td>
                    <td>
                      <span> {{ collect.generator.name }}</span>
                    </td>
                    <td>
                      <span>{{ collect.items.item.name }}</span>
                    </td>
                    <td>
                      <span>{{ collect.status_label }}</span>
                    </td>
                    <td>
                      <span>{{ collect.modality_label }}</span>
                    </td>
                    <td>
                      <span>{{ collect.date_label }}</span>
                    </td>
                    <td>
                      <span>{{ collect.city }}</span>
                    </td>
                    <td>
                      <span>{{ collect.items.ammount }}</span>
                    </td>
                    <td>
                      <span>{{ collect.items.item.unity.unity }}</span>
                    </td>
                    <td>
                      <span>{{ collect.paid }}</span>
                    </td>
                    <td>
                      <span>{{ collect.total }}</span>
                    </td>
                    <td style="text-align: center;">
                      <a :href="url + '/collects/certificate' + '?item_closet_id=' + collect.closet_id + '&output=pdf'" v-if="collect.license_number !== '' && collect.license_number !== 'null' && collect.license_number !== null && (collect.status === '3' || collect.status === '4')"><i class="fas fa-print print_icon"></i></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <loading v-else-if="loading"></loading>
            <div v-else>Sem dados para mostrar</div>
          </div>
        </div>
        <div v-if="pagedCollects.length > 0 && loading === false" class="paginator">
          <button type="button" class="paginator-control" :disabled="page == 1" :class="{ disabled: page == 1 }" @click="page--">
            <i class="fa fa-chevron-left"></i>
          </button>
          <div v-for="(pageNumber, index) in pages" :key="index" @click="page = pageNumber" class="paginator-control" :class="{active: pageNumber === page}"> {{pageNumber}} </div>
          <button type="button" @click="page++" :disabled="page == pages.length" :class="{ disabled: page == pages.length }" class="paginator-control">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

const defaultForm = {
  id: '',
  type: 'PF',
  city_id: null,
  province_id: null,
  name: '',
  email: '',
  phone: '',
  company_id: 1
}
export default {
  data: () => ({
    form: { ...defaultForm },
    filter: 0,
    filter_collector: '',
    filter_generator: '',
    filter_material: '',
    filter_status: '',
    filter_type: '',
    filter_city: '',
    list_collector: [],
    list_generator: [],
    list_material: [],
    list_status: [],
    list_type: [],
    list_city: [],
    search: null,
    error: null,
    load: true,
    modal: false,
    editing: {},
    msg: null,
    collects: [],
    province_id: '',
    collectorId: '',
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    loading: false,
    page: 1,
    perPage: 10,
    pages: [],
    url: 'https://dev.api.recicla.com.br/api'
  }),
  components: {
    Loading: () => import('../../components/loading.vue')
  },
  computed: {
    collectorName () {
      const [collector] = this.collectors.filter(i => i.id === this.collectorId)
      return collector ? collector.name : '-'
    },
    monthYear () {
      const date = new Date(Date.UTC(this.year, this.month))
      const formated = new Intl.DateTimeFormat('pt-BR', {
        dateStyle: 'full'
      }).format(date)
      const [year, month] = formated.split(' de ').reverse()
      return `${month} de ${year}`
    },
    pagedCollects: function () {
      let filtered = this.collects
      if (this.search !== '' && this.search) {
        const search = this.search
        filtered = filtered.filter((value) => {
          console.log(value.modality_label, '=', search, value.coletor.name.includes(search) ||
              value.generator.name.includes(search) ||
              value.items.item.name.includes(search) ||
              value.status_label.includes(search) ||
              value.modality_label.includes(search) ||
              value.date_label.includes(search) ||
              value.city.includes(search) ||
              value.items.item.unity.unity.includes(search))
          return value.coletor.name.includes(search) ||
              value.generator.name.includes(search) ||
              value.items.item.name.includes(search) ||
              value.status_label.includes(search) ||
              value.modality_label.includes(search) ||
              value.date_label.includes(search) ||
              value.city.includes(search) ||
              value.items.item.unity.unity.includes(search)
        })
      }

      return this.paginate(filtered)
    }
  },
  methods: {
    ...mapActions('collector', { fetchCollectors: 'fetch' }),
    ...mapActions('client', ['fetch']),

    setPages (value) {
      this.pages = []
      const numberOfPages = Math.ceil(value.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate (value) {
      const page = this.page
      const perPage = this.perPage
      const from = (page * perPage) - perPage
      const to = (page * perPage)
      this.setPages(value)
      return value.slice(from, to)
    },
    openEditModal (item = null) {
      this.editing = {}
      if (!item) return
      const itemToEdit = { ...item }
      this.editing = itemToEdit
      this.modal = true
    },
    async deleteClient (item = null) {
      const url = 'clients/destroy'

      this.$http.delete(url, { body: { id: item } }).then(response => {
        this.msg = response.msg
      })
    },
    openModal (item = null) {
      this.selectedEntity = item
      this.modal = true
    },
    changeDate (to) {
      let { month, year } = this
      if (to === 'next') month++
      else if (to === 'prev') month--
      if (month === 0) {
        month = 12
        year--
      } else if (month === 13) {
        month = 1
        year++
      }
      this.month = month
      this.year = year
    },
    downloadItem () {
      const { startDate, endDate } = this
      let url = 'collects/report?page=1'
      const filters = []

      if (this.filter_collector !== '' && this.filter_collector !== null) filters.push('collector=' + this.filter_collector)
      if (this.filter_generator !== '' && this.filter_generator !== null) filters.push('generator=' + this.filter_generator)
      if (this.filter_material !== '' && this.filter_material !== null) filters.push('material=' + this.filter_material)
      if (this.filter_status !== '' && this.filter_status !== null) filters.push('status=' + this.filter_status)
      if (this.filter_type !== '' && this.filter_type !== null) filters.push('type=' + this.filter_type)
      if (this.filter_city !== '' && this.filter_city !== null) filters.push('city=' + this.filter_city)

      url += filters.length > 0 ? '&' + filters.join('&') : ''

      if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`
      }

      this.$http.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'coletas'
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    async getHits (update = 0) {
      this.loading = true
      const { startDate, endDate } = this
      let url = 'collection/getCertifieds?page=1'
      const filters = []

      if (this.filter_collector !== '' && this.filter_collector !== null) filters.push('collector=' + this.filter_collector)
      if (this.filter_generator !== '' && this.filter_generator !== null) filters.push('generator=' + this.filter_generator)
      if (this.filter_material !== '' && this.filter_material !== null) filters.push('material=' + this.filter_material)
      if (this.filter_status !== '' && this.filter_status !== null) filters.push('status=' + this.filter_status)
      if (this.filter_type !== '' && this.filter_type !== null) filters.push('type=' + this.filter_type)
      if (this.filter_city !== '' && this.filter_city !== null) filters.push('city=' + this.filter_city)

      url += filters.length > 0 ? '&' + filters.join('&') : ''

      if (startDate && endDate) {
        url += `&startDate=${startDate}&endDate=${endDate}`
      }

      // if (this.search) url += `&search=${this.search}`
      const { data } = await this.$http.get(url)

      this.collects = data.data.orders
      if (update === 1) {
        this.list_collector = data.data.collectors
        this.list_generator = data.data.generators
        this.list_material = data.data.material
        this.list_status = data.data.status
        this.list_type = data.data.type
        this.list_city = data.data.city
      }

      this.loading = false
    }
  },
  mounted () {
    const localUser = localStorage.getItem('user')
    const user = JSON.parse(localUser)

    this.id = user.id
    this.getHits(1)
  },
  filters: {
    money (val) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(parseFloat(val))
    }
  },
  watch: {
    collectorId (val, old) {
      if (val === old) return
      this.getHits()
    },
    startDate (val) {
      if (val && this.endDate) this.getHits(1)
    },
    endDate (val) {
      if (val && this.startDate) this.getHits(1)
    },
    filter () {
      this.getHits(1)
    },
    search (val, old) {
      if (val === old) return null
      this.getHits(1)
    }
  }
}
</script>

<style>
.sidebar__header {
  display: flex;
  justify-content: space-between;
}
.tabs__search {
  width: 40%;
}

.table {
  table-layout: fixed;
}

th,
td {
  font-weight: 400;
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

td span {
  font-weight: 400;
  font-size: 14px;
}

.table tbody tr td:nth-child(1) {
  width: 200px;
  display: inline-flex;
}

.alert_card {
  font-size: 10pt;
  text-align: center;
  padding: 3px;
  border-radius: 4px;
  width: 50px;
}

.circle_initial {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #fff;
  margin-right: 7px;
  position: relative;
}

.circle_initial p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.print_icon {
  color: #16b666;
  margin-right: 15px;
  font-size: 15pt;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
</style>
